<template lang="">
    <div>
        <Sold/>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satılanlar", route: "/lists/sold" },
    ]);
  },
    components: {
    Sold: () => import('@/components/lists/Sold.vue'),
}
}
</script>
<style lang="">
    
</style>